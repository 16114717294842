import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
// import formHandler from '@/use/formHandler'

export default () => {
  const data = ref({})
  const organisationData = ref([])
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  // const editForm = formHandler()

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)

    useApi.requestV2('get', `v1/users/${payload}`).then((response) => {
      data.value = response.data
      organisationData.value = response.data.organisations

      loadHandler.setLoadingState('get_details', false)
    })
  }

  function unassigUser(payload) {
    loadHandler.setLoadingState('unassign_users', true)
    const user_id = data.value.user_id

    useApi
      .requestV2('delete', `v1/organisations/${payload}/users?ids=${user_id}`)
      .then(() => {
        getDetails(user_id)
        loadHandler.setLoadingState('unassign_users', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('unassign_users', false)
      })
  }

  const fields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Email',
      key: 'email',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Object ID',
      key: 'user_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Auth0 id',
      key: 'auth0_user_id',
      type: 'string',
      searchable: true,
    },
  ])

  const organisationFields = ref([
    {
      label: 'Object ID',
      key: 'object_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'string',
      searchable: true,
    },
  ])

  return {
    getDetails,
    data,
    loadHandler,
    fields,
    organisationData,
    organisationFields,
    unassigUser,
  }
}
