<template>
  <div class="h-full">
    <div class="my-8" v-if="title">
      <basePageTitle title="Opmerkingen" class="my-8"></basePageTitle>
    </div>

    <baseCard>
      <!-- comments -->
      <textarea
        :disabled="useComments.inputDisabled.value"
        :model="useComments.comment.value"
        :value="useComments.comment.value"
        @input="useComments.setInput($event)"
        placeholder="Schrijf een bericht..."
        class="resize-y rounded-md bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
      ></textarea>
      <baseButton @action="onAdd()" size="small" class="my-2 mr-2" :loading="useComments.loadHandler.states['add']"
        >Versturen</baseButton
      >
      <!-- loader -->
      <div class="my-2 ml-2">
        <baseLoader
          class="text-slate-500"
          :loading="useComments.loadHandler.states.list || useComments.loadHandler.states.delete"
        ></baseLoader>
      </div>

      <div v-if="!useComments.loadHandler.states.list" class="max-h-[300px] overflow-y-auto">
        <div v-for="(comment, commentIndex) in useComments.comments.value" :key="commentIndex">
          <div class="my-4 text-gray-500">
            <div class="rounded bg-white p-3 border border-solid border-gray-50">
              <div class="flex justify-between">
                <!-- links -->
                <div>
                  <p class="text-sm mb-3">{{ comment.content }}</p>
                </div>

                <!-- rechts -->
                <div>
                  <baseButton
                    v-if="seeDeleteButton(comment)"
                    size="small"
                    variant="neutral"
                    @action="onDeleteComment(comment, commentable_id, commentIndex)"
                  >
                    <featherIcon class="w-4 h-4" icon="XIcon" />
                  </baseButton>
                </div>
              </div>
              <!-- <p class="text-sm mt-3">{{ comment.content }}</p> -->
              <p class="text-xs">
                {{ useFilter.filter(comment.date_created, 'dateFromEpochDay') }} - {{ comment.username }}
              </p>
              <!-- <p class="text-xs">{{ useFilter.filter(comment.date_created, 'dateFromEpochDay') }}</p> -->
            </div>
          </div>
        </div>
      </div>
    </baseCard>

    <confimationModal :handler="confirm" />
  </div>
</template>

<script>
import commentHandler from '@/use/commentHandler'
import filterHandler from '@/use/filterHandler'
import { useAuth0 } from '@auth0/auth0-vue'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'

export default {
  props: {
    commentable_id: {
      type: String,
      default: '',
    },
    commentable_type: {
      type: String,
      default: '',
    },
    title: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['add'],
  setup(props) {
    const useComments = commentHandler()
    const useFilter = filterHandler()
    const auth = useAuth0()
    const confirm = confirmationHandler()

    function onAdd() {
      const payload = {
        commentable_id: props.commentable_id,
        commentable_type: props.commentable_type,
      }

      useComments.addComment(payload)
    }

    function seeDeleteButton(comment) {
      return comment.auth0_user_id === auth.user.value.sub
    }

    async function onDeleteComment(comment, commentable_id, commentIndex) {
      const ok = await confirm.open({
        title: 'Opmerking verwijderen?',
        message: 'Let op, de gegevens worden permanent verwijderd',
      })
      if (ok) {
        useComments.deleteComment(comment, commentable_id, commentIndex)
      }
    }

    return {
      useComments,
      onAdd,
      useFilter,
      seeDeleteButton,
      onDeleteComment,
      confirm,
    }
  },
  components: {
    confimationModal,
  },
}
</script>
