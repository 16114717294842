import { ref, computed } from 'vue'
import { eachDayOfInterval, endOfMonth, getYear, startOfMonth, fromUnixTime, getDate, getMonth } from 'date-fns'

export default (initialValue) => {
  const selectedYear = ref(initialValue ? getYear(fromUnixTime(initialValue / 1000)) : getYear(new Date()))
  const selectedMonth = ref(initialValue ? getMonth(fromUnixTime(initialValue / 1000)) : new Date().getMonth())
  const selectedDay = ref(initialValue ? getDate(fromUnixTime(initialValue / 1000)) : new Date().getDate())
  const result = ref(initialValue || null)

  function isWeekend(date) {
    const dayOfWeek = date.getDay()
    return dayOfWeek === 0 || dayOfWeek === 6
  }

  const today = ref(new Date())

  function isToday(dayDate) {
    const now = new Date(today.value)
    return (
      dayDate.getDate() === now.getDate() &&
      dayDate.getMonth() === now.getMonth() &&
      dayDate.getFullYear() === now.getFullYear()
    )
  }

  function isSelected(dayDate) {
    return (
      selectedDay.value &&
      dayDate.getDate() === selectedDay.value &&
      dayDate.getMonth() === selectedMonth.value &&
      dayDate.getFullYear() === selectedYear.value
    )
  }

  function dayOffset() {
    const start = new Date(selectedYear.value, selectedMonth.value, 1)
    return (start.getDay() + 6) % 7 // +6 makes Sunday (0) index 6
  }

  const monthNames = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ]

  const weekdays = ['m', 'd', 'w', 'd', 'v', 'z', 'z']

  const years = computed(() => {
    const currentYear = getYear(new Date())
    return Array.from({ length: 11 }, (_, i) => currentYear - 5 + i).sort((a, b) => b - a)
  })

  const months = computed(() => {
    const currentYear = getYear(new Date())
    const monthNames = [
      'Januari',
      'Februari',
      'Maart',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Augustus',
      'September',
      'Oktober',
      'November',
      'December',
    ]
    return monthNames.map((name, index) => {
      const date = new Date(currentYear, index)
      return { number: index + 1, name: name, date }
    })
  })

  const days = computed(() => {
    const selectedDate = new Date(selectedYear.value, selectedMonth.value) // adjusted to directly use selectedMonth
    const start = startOfMonth(selectedDate)
    const end = endOfMonth(selectedDate)
    return eachDayOfInterval({ start, end }).map((date) => ({
      number: date.getDate(),
      date: date, // store the full date object to use when a day is selected
    }))
  })

  function getResult() {
    const date = new Date(selectedYear.value, selectedMonth.value, selectedDay.value)
    return date
  }

  return {
    weekdays,
    today,
    monthNames,
    dayOffset,
    isSelected,
    isToday,
    isWeekend,
    result,
    years,
    months,
    days,
    selectedYear,
    selectedMonth,
    selectedDay,
    getResult,
  }
}
