<template>
  <div class="h-full min-h-0 overflow-auto p-4">
    <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>

    <baseTable
      :fields="useUsers.fields.value"
      :records="useUsers.records.value"
      :records-per-page="15"
      :add-button="true"
      :edit-button="true"
      :delete-button="true"
      :clickAble="true"
      :clickCallback="goToDetails"
      :loading="useUsers.loadHandler.somethingLoading.value"
      :selectable="false"
      :hover-effect="true"
      keyField="user_id"
      @add="useUsers.openAdd"
      @edit="useUsers.openEdit"
      @delete="useUsers.deleteRecord"
      leadIcon="UsersIcon"
    />

    <!-- <baseButton @action="useUsers.sync()" :loading="useUsers.loadHandler.states[`sync`]" icon="RefreshCwIcon">
      <featherIcon class="w-5 h-5" icon="RefreshCwIcon" />
    </baseButton> -->

    <!-- add modal -->
    <baseModal v-if="useUsers.showAdd.value" @close="useUsers.closeAdd" :title="$translate('Add')" size="l">
      <baseForm
        :handler="useUsers.addForm"
        :loading="useUsers.loadHandler.states['add_record'] || useUsers.loadHandler.states['get_roles']"
      />

      <template v-slot:buttons>
        <baseButton
          @action="useUsers.addRecord()"
          :loading="useUsers.loadHandler.states[`add_record`]"
          :disabled="useUsers.addForm.errors.value.hasErrors"
        >
          {{ $translate('Add') }}
        </baseButton>
      </template>
    </baseModal>

    <!-- edit modal -->
    <baseModal v-if="useUsers.showEdit.value" @close="useUsers.closeEdit" :title="$translate('Add')" size="s">
      <baseForm
        :handler="useUsers.editForm"
        :loading="useUsers.loadHandler.states['edit_record'] || useUsers.loadHandler.states['get_roles']"
      />
      <template v-slot:buttons>
        <baseButton @action="useUsers.editRecord()" :loading="useUsers.loadHandler.states[`edit_record`]">
          {{ $translate('Edit') }}
        </baseButton>
      </template>
    </baseModal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import usersHandler from '@/use/viewHandlerUsers'

export default {
  setup() {
    const route = useRoute()
    const useUsers = usersHandler()
    const objectId = route.params.objectId
    const meta = computed(() => route.meta)
    const router = useRouter()

    const pageTitle = computed(() => {
      return meta.value.title
    })

    // const pageSuffix = computed(() => {
    //   if (!nodeDetails.loadHandler.states.get_details) {
    //     return `${nodeDetails.data.value.provider_identifier}`
    //   }
    //   return ''
    // })

    function goToDetails(record) {
      const objectId = record.user_id
      router.push({ name: 'user-view', params: { objectId: objectId } })
    }

    const pageSuffix = ''

    onMounted(() => {
      useUsers.getRecords()
    })

    return {
      useUsers,
      objectId,
      pageTitle,
      pageSuffix,
      meta,
      goToDetails,
    }
  },
}
</script>
