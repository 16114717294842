import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import { ref } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'

const comments = ref([])
const loadHandler = loadingHandler()
const inputDisabled = ref(false)

export default () => {
  const useApi = apiHandler()

  const auth = useAuth0()

  const comment = ref(null)

  async function list(payload) {
    loadHandler.setLoadingState('list', true)
    try {
      const commentable_id = payload.commentable_id
      const commentable_type = payload.commentable_type

      const response = await useApi.requestV2(
        'get',
        `v1/comments/?commentable_id=${commentable_id}&commentable_type=${commentable_type}`
      )

      const response_data = response.data

      comments.value = response_data
    } finally {
      loadHandler.setLoadingState('list', false)
    }
  }

  function setInput(input) {
    comment.value = input.target.value
  }

  function resetInput() {
    comment.value = ''
  }

  async function addComment(payload) {
    inputDisabled.value = true
    payload['content'] = comment.value
    payload['username'] = auth.user.value.name
    payload['auth0_user_id'] = auth.user.value.sub

    loadHandler.setLoadingState('add', true)
    try {
      await useApi.requestV2('put', `v1/comments/`, payload)
      resetInput()

      const commentPayload = {
        commentable_id: payload.commentable_id,
        commentable_type: payload.commentable_type,
      }

      list(commentPayload)
    } finally {
      inputDisabled.value = false
      loadHandler.setLoadingState('add', false)
    }
  }

  async function deleteComment(comment, commentIndex) {
    loadHandler.setLoadingState('delete', true)
    try {
      await useApi.requestV2('delete', `v1/comments/${comment.id}`)
      comments.value.splice(commentIndex, 1)
    } finally {
      loadHandler.setLoadingState('delete', false)
    }
  }

  return {
    loadHandler,
    list,
    comments,
    comment,
    setInput,
    addComment,
    deleteComment,
    inputDisabled,
  }
}
