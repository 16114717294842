<template>
  <div class="h-full min-h-0 overflow-auto p-4">
    <base-page-title
      class="mb-8 mt-4"
      :title="pageTitle"
      :suffix="useUserDetails.data.value.name"
      :icon="meta.titleIcon"
    ></base-page-title>

    <!-- list -->
    <div class="flex flex-row">
      <div class="basis-1/3">
        <base-card>
          <base-list :data="useUserDetails.data.value" :fields="useUserDetails.fields.value" :demo="false" />
        </base-card>
      </div>
      <div class="basis-1/3"></div>
      <div class="basis-1/3"></div>
    </div>

    <base-page-title class="my-8" :title="'Organisaties'" :icon="'UsersIcon'"></base-page-title>

    <baseTable
      :fields="useUserDetails.organisationFields.value"
      :records="useUserDetails.organisationData.value"
      :records-per-page="15"
      :add-button="false"
      :edit-button="false"
      :delete-button="true"
      :clickAble="false"
      :loading="useUserDetails.loadHandler.somethingLoading.value"
      :selectable="false"
      :hover-effect="true"
      keyField="object_id"
      @add="useUserDetails.openAdd"
      @delete="useUserDetails.unassigUser"
      leadIcon="UsersIcon"
    />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { onMounted, computed } from 'vue'
import userDetailsHandler from '@/use/viewHandlerUserDetails'

export default {
  setup() {
    const route = useRoute()
    const useUserDetails = userDetailsHandler()
    const objectId = route.params.objectId
    const meta = computed(() => route.meta)

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = ''

    onMounted(() => {
      useUserDetails.getDetails(objectId)
    })

    return {
      useUserDetails,
      objectId,
      pageTitle,
      pageSuffix,
      meta,
    }
  },
}
</script>
