<template>
  <div>
    <div v-if="title" class="my-8">
      <basePageTitle title="Bijlagen" class="my-8"></basePageTitle>
    </div>

    <baseCard class="max-h-[500px] overflow-y-auto">
      <input
        type="file"
        ref="fileInput"
        @change="handleFileUpload"
        style="display: none"
        accept="image/*, .doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx"
      />

      <!-- toevoegen button -->
      <baseButton
        @action="triggerFileInput"
        size="small"
        class="my-2 mr-2"
        :disabled="useAttachments.loadHandler.somethingLoading.value"
      >
        Toevoegen
      </baseButton>

      <div v-for="(attachment, attachmentIndex) in useAttachments.attachments.value" :key="attachmentIndex">
        <div class="my-4 text-gray-500">
          <div class="rounded bg-white p-3 border border-solid border-gray-50">
            <div class="flex justify-between">
              <!-- links -->
              <div>
                <p class="text-sm font-bold">{{ attachment.file_name }}</p>
                <p class="text-xs">{{ useFilter.filter(attachment.date_created, 'dateFromTimestamp') }}</p>
              </div>

              <!-- rechts -->
              <div>
                <baseButton
                  size="small"
                  variant="neutral"
                  @action="useAttachments.downloadAttachment(attachment.id)"
                  class="mr-2"
                >
                  <featherIcon class="w-4 h-4" icon="DownloadIcon" />
                </baseButton>
                <baseButton
                  size="small"
                  variant="neutral"
                  @action="onDeleteAttachment(attachment, attachable_id, attachmentIndex)"
                >
                  <featherIcon class="w-4 h-4" icon="XIcon" />
                </baseButton>
              </div>
            </div>
            <!-- <p class="text-sm mt-3">{{ comment.content }}</p> -->
          </div>
        </div>
      </div>

      <!-- loader -->
      <div class="my-2 ml-2">
        <baseLoader class="text-slate-500" :loading="useAttachments.loadHandler.somethingLoading.value"></baseLoader>
      </div>
    </baseCard>

    <confimationModal :handler="confirm" />
  </div>
</template>

<script>
import { ref } from 'vue'
import attachmentHandler from '@/use/attachmentHandler'
import filterHandler from '@/use/filterHandler'
import { useAuth0 } from '@auth0/auth0-vue'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'

export default {
  props: {
    attachable_id: {
      type: String,
      default: '',
    },
    attachable_type: {
      type: String,
      default: '',
    },
    title: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['add'],
  setup(props) {
    const useAttachments = attachmentHandler()
    const useFilter = filterHandler()
    const auth = useAuth0()
    const confirm = confirmationHandler()

    // function onAdd() {
    //   const payload = {
    //     attachable_id: props.attachable_id,
    //     attachable_type: props.attachable_type,
    //   }

    //   useAttachments.addAttachment(payload)
    // }

    function seeDeleteButton(attachment) {
      return attachment.userid === auth.user.value.sub
    }

    async function onDeleteAttachment(attachment, attachable_id) {
      const ok = await confirm.open({
        title: 'Bijlage verwijderen?',
        message: 'Let op, de gegevens worden permanent verwijderd',
      })
      if (ok) {
        useAttachments.deleteAttachment(attachment, attachable_id, props.attachable_type)
      }
    }

    // fileinput
    const fileInput = ref(null)

    function triggerFileInput() {
      fileInput.value.click()
    }

    function handleFileUpload(event) {
      const files = event.target.files
      if (files.length > 0) {
        useAttachments.uploadAttachment(files[0], props.attachable_id, props.attachable_type)
      }
    }

    return {
      useAttachments,
      // onAdd,
      useFilter,
      seeDeleteButton,
      onDeleteAttachment,
      confirm,
      triggerFileInput,
      handleFileUpload,
      fileInput,
    }
  },
  components: {
    confimationModal,
  },
}
</script>
