import { ref, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'

export default () => {
  const data = ref({})
  const dataHealthCheck = ref({})
  const dataHealthTime = ref({})
  const organisationData = ref([])
  const useApi = apiHandler()
  const loadHandler = loadingHandler()

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    useApi.requestV2('get', `v1/connectors/${payload}`).then((response) => {
      data.value = response.data
      organisationData.value = response.data.organisations
      getHealthCheck(payload)
      getHealthTimeData(payload)
      loadHandler.setLoadingState('get_details', false)
    })
  }

  function getHealthCheck(payload) {
    loadHandler.setLoadingState('get_health_check', true)
    useApi.requestV2('get', `v1/connectors/${payload}/health`).then((response) => {
      dataHealthCheck.value = response.data
      loadHandler.setLoadingState('get_health_check', false)
    })
  }

  function getHealthTimeData(payload) {
    loadHandler.setLoadingState('get_health_time_data', true)
    useApi.requestV2('get', `v1/connectors/${payload}/timeseries`).then((response) => {
      dataHealthTime.value = response.data
      loadHandler.setLoadingState('get_health_time_data', false)
    })
  }

  const calculateUptime = computed(() => {
    if (!dataHealthTime.value || !dataHealthTime.value.length) return 0
    const healthy = dataHealthTime.value.filter((check) => check.is_healthy).length
    return ((healthy / dataHealthTime.value.length) * 100).toFixed(2)
  })

  const formatChartData = (data) => {
    if (!data || !data.length) return []

    const sortedData = [...data].sort((a, b) => a.timestamp - b.timestamp)
    return sortedData.map((check) => ({
      x: check.timestamp * 1000,
      y: 1,
      color: check.is_healthy ? '#10B981' : '#EF4444', // Green for healthy, red for unhealthy
    }))
  }

  const chartOptions = computed(() => ({
    chart: {
      type: 'column',
      height: 100,
      backgroundColor: 'transparent',
      spacing: [0, 0, 0, 0],
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        enabled: true,
      },
      lineWidth: 0,
      tickWidth: 0,
      gridLineWidth: 1,
      tickInterval: 14 * 24 * 3600,
    },
    yAxis: {
      min: 0,
      max: 1,
      visible: false,
      gridLineWidth: 0,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 4,
        crisp: true,
      },
      series: {
        states: {
          hover: {
            enabled: true,
            brightness: 0.1,
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderWidth: 0,
      borderRadius: 4,
      shadow: true,
      padding: 8,
      formatter: function () {
        const date = new Date(this.x).toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })
        const status = this.color === '#10B981' ? 'Healthy' : 'Unhealthy'
        return `<b>${date}</b><br/>${status}`
      },
      style: {
        fontSize: '12px',
      },
    },
    credits: {
      enabled: false,
    },
    exporting: { enabled: false },
    series: [
      {
        name: 'Health Status',
        data: dataHealthTime.value ? formatChartData(dataHealthTime.value) : [],
      },
    ],
  }))

  return {
    getDetails,
    data,
    loadHandler,
    organisationData,
    dataHealthCheck,
    getHealthCheck,
    dataHealthTime,
    chartOptions,
    calculateUptime,
  }
}
