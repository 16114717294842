<template>
  <span v-if="show" :class="pillClass" class="content-center">
    <featherIcon icon="AlertTriangleIcon" class="w-5 h-5 mx-auto text-amber-600" />
  </span>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'PillMedium',
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  setup(props) {
    const show = computed(() => {
      return props.value === null ? false : props.value
    })
    const pillClass = ''
    return { pillClass, show }
  },
}
</script>
