<template>
  <!-- string -->
  <input
    :class="requiredAndFilled(field) ? 'bg-white border-gray-200' : 'bg-red-100 border-red-200'"
    :disabled="handler.loading.value || disabled || field.disabled"
    v-if="field.type === 'string'"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
    :id="field.key"
    type="text"
    :value="handler.data.value[field.key]"
    @input="handler.validate($event, 'string', [], field.key)"
  />

  <!-- string -->
  <input
    :class="requiredAndFilled(field) ? 'bg-white border-gray-200' : 'bg-red-100 border-red-200'"
    :disabled="handler.loading.value || disabled || field.disabled"
    v-if="field.type === 'email'"
    class="bg-white appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
    :id="field.key"
    type="text"
    :value="handler.data.value[field.key]"
    @input="handler.validate($event, 'email', [], field.key)"
  />

  <!-- password -->
  <input
    :class="requiredAndFilled(field) ? 'bg-white border-gray-200' : 'bg-red-100 border-red-200'"
    :disabled="handler.loading.value || disabled || field.disabled"
    v-if="field.type === 'password'"
    class="bg-white appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm disabled:bg-gray-100"
    :id="field.key"
    type="password"
    :value="handler.data.value[field.key]"
    @input="handler.validate($event, 'password', [], field.key)"
  />

  <!-- calculation -->
  <input
    :class="requiredAndFilled(field) ? 'bg-white' : 'bg-red-100 border-red-200'"
    disabled
    v-if="field.type === 'calculation'"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-xl disabled:bg-gray-100 disabled:border-gray-200"
    :id="field.key"
    type="text"
    :value="handler.data.value[field.key]"
    @input="handler.validate($event, 'calculation', [], field.key)"
  />

  <!-- number -->
  <input
    v-show="dependentCheck(field)"
    :class="requiredAndFilled(field) ? 'bg-white' : 'bg-red-100 border-red-200'"
    v-if="field.type === 'number'"
    :disabled="handler.loading.value || disabled || field.disabled"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
    :id="field.key"
    :value="handler.data.value[field.key]"
    type="text"
    :placeholder="field.placeholder ? field.placeholder : ''"
    @input="handler.validate($event, 'number', [], field.key)"
  />

  <!-- integer -->
  <div v-if="field.type === 'integer'" class="flex">
    <div class="relative flex-1">
      <input
        :class="requiredAndFilled(field) ? 'bg-white border-gray-200' : 'bg-red-100 border-red-200'"
        :disabled="handler.loading.value || disabled || field.disabled"
        class="bg-white appearance-none border border-gray-200 rounded-l w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
        :id="field.key"
        type="text"
        :value="handler.data.value[field.key]"
        :placeholder="field.placeholder ? field.placeholder : ''"
        @input="handler.validate($event, field.integer_type, [], field.key)"
      />
    </div>
    <div class="flex flex-col border border-l-0 border-gray-200 rounded-r">
      <button
        type="button"
        :disabled="handler.loading.value || disabled || field.disabled"
        class="flex-1 px-2 hover:bg-gray-50 border-b border-gray-200 rounded-tr disabled:opacity-50 disabled:hover:bg-white"
        @click="incrementValue(field.key, 1)"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <button
        type="button"
        :disabled="handler.loading.value || disabled || field.disabled"
        class="flex-1 px-2 hover:bg-gray-50 rounded-br disabled:opacity-50 disabled:hover:bg-white"
        @click="incrementValue(field.key, -1)"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
  </div>

  <!-- percentage -->
  <input
    :class="requiredAndFilled(field) ? 'bg-white' : 'bg-red-100 border-red-200'"
    v-if="field.type === 'percentage'"
    :disabled="handler.loading.value || disabled || field.disabled"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
    :id="field.key"
    :value="handler.data.value[field.key]"
    type="text"
    @input="handler.validate($event, 'percentage', [], field.key)"
  />

  <!-- selects -->
  <div class="relative" v-if="field.type === 'select'" tabindex="0">
    <!-- if disabled -->
    <div v-if="handler.loading.value || disabled || field.disabled">
      <div
        class="flex justify-between bg-gray-100 border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm"
      >
        <div>{{ handler.data.value[field.key] }}</div>
        <div>
          <!-- <featherIcon class="w-4 h-4 my-auto mr-2" icon="ChevronDownIcon" /> -->
        </div>
      </div>
    </div>
    <!-- if not disabled -->
    <div
      v-else
      ref="selectRef"
      :class="requiredAndFilled(field) ? 'bg-white' : 'bg-red-100 border-red-200'"
      class="flex justify-between bg-white border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200 cursor-pointer"
      @click="toggleSelect()"
    >
      <div>
        {{
          field.translate_value
            ? useTranslate.t(getLabel(handler.data.value[field.key], field.options))
            : getLabel(handler.data.value[field.key], field.options)
        }}
      </div>
      <div>
        <featherIcon class="w-4 h-4 my-auto mr-2" icon="ChevronDownIcon" />
      </div>
    </div>

    <ul
      class="absolute bg-white w-full border border-gray-200 p-2 text-gray-700 text-sm mt-1 shadow-lg z-50 overflow-auto"
      v-if="selectOpen"
    >
      <li
        class="cursor-pointer hover:bg-gray-50"
        v-for="(option, index) in field.options"
        :key="index"
        @click="handler.validate({ target: { value: option.value } }, 'select', [], field.key)"
      >
        {{ field.translate_value ? useTranslate.t(option.label) : option.label }}
      </li>
    </ul>
  </div>

  <!-- subtitle -->
  <span class="text-gray-500 text-sm" v-if="field.type === 'subtitle'">{{ field.label }} </span>

  <!-- list -->
  <baseListInput
    v-if="field.type === 'list'"
    :disabled="handler.loading.value || disabled || field.disabled"
    :values="handler.data.value[field.key]"
    :handler="handler"
    :keyField="field.key"
    :fieldConfig="field.fieldConfig"
    :displayTemplate="field.displayTemplate"
    :modalSize="field.modalSize"
    @add="(...args) => handler.addListItem(field.key, ...args)"
    @remove="(...args) => handler.removeListItem(field.key, ...args)"
    @edit="(...args) => handler.editListItem(field.key, ...args)"
    @duplicate="(...args) => handler.duplicateListItem(field.key, ...args)"
  />

  <!-- toggle -->
  <span v-if="field.type === 'toggle'">
    <label class="relative inline-flex items-center cursor-pointer">
      <input
        :value="true"
        :disabled="handler.loading.value || disabled"
        type="checkbox"
        class="sr-only peer"
        :checked="handler.data.value[field.key] === true ? true : false"
        @input="handler.validate($event, 'check', [], field.key)"
      />
      <div
        class="my-auto w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-700"
      ></div>
    </label>
  </span>

  <!-- date -->
  <baseDateInput
    v-if="field.type === 'date'"
    :disabled="handler.loading.value || disabled || field.disabled"
    :value="handler.data.value[field.key]"
    :handler="handler"
    :keyField="field.key"
    :fieldConfig="field.fieldConfig"
    :displayTemplate="field.displayTemplate"
    :modalSize="field.modalSize"
    @add="(...args) => handler.setValue(field.key, ...args)"
    @remove="(...args) => handler.setValue(field.key, ...args)"
    @edit="(...args) => handler.setValue(field.key, ...args)"
  />

  <!-- area -->
  <span v-if="field.type === 'area'" v-show="dependentCheck(field)" class="flex">
    <input
      :class="requiredAndFilled(field) ? 'bg-white' : 'bg-red-100 border-red-200'"
      :disabled="handler.loading.value || disabled || field.disabled"
      class="bg-white appearance-none border border-gray-200 rounded-l w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm"
      :id="field.key"
      type="text"
      :value="handler.data.value[field.key]"
      @input="handler.validate($event, 'number', [], field.key)"
    />

    <baseButton class="rounded-r ml-1" @action="calculatorHandler.open(handler, field.key)" size="small"
      ><CalculatorIcon class="h-5 w-5"
    /></baseButton>
  </span>

  <!-- file -->
  <span v-if="field.type === 'file'" class="flex">
    <input
      :class="requiredAndFilled(field) ? 'bg-white' : 'bg-red-100 border-red-200'"
      disabled
      class="bg-white appearance-none border border-gray-200 rounded-l w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm"
      type="string"
      :value="parsedFilename(handler.data.value[field.key])"
    />

    <!-- uploadbutton -->
    <label :for="`${field.key}`" class="cursor-pointer bg-sky-700 px-4 py-3 text-sm text-white">
      <featherIcon class="w-5 h-5" icon="UploadIcon"
    /></label>

    <!-- delete button -->
    <!-- <label class="cursor-pointer bg-red-600 rounded-r px-2 py-1.5 text-xs text-white">
      <featherIcon class="w-5 h-5" icon="XIcon"
    /></label> -->

    <base-button
      class="rounded-l-none"
      variant="danger"
      :disabled="parsedFilename(handler.data.value[field.key]) ? false : true"
      @action="handler.removeData(field.key)"
      ><featherIcon class="w-5 h-5" icon="XIcon"
    /></base-button>

    <input
      :class="requiredAndFilled(field) ? 'bg-white' : 'bg-red-100 border-red-200'"
      :disabled="handler.loading.value || disabled"
      class="hidden"
      :id="`${field.key}`"
      type="file"
      accept=".docx"
      @input="handler.validate($event, 'file', [], field.key)"
    />

    <!-- <baseButton class="rounded-r ml-1" @action="calculatorHandler.open(handler, field.key)" size="small">
      <featherIcon class="w-5 h-5" icon="UploadIcon" />
    </baseButton> -->
  </span>

  <!-- Error messages for this field -->
  <ul
    v-if="handler.errors.value.fields[field.key] && handler.errors.value.fields[field.key].length > 0"
    class="text-xs text-red-400 mt-1"
  >
    <li v-for="(error, index) in handler.errors.value.fields[field.key]" :key="index" class="error-item">
      {{ error }}
    </li>
  </ul>
</template>

<script>
import { CalculatorIcon } from '@heroicons/vue/outline'
import useCalculator from '@/use/calculatorHandler'
// import useCalculator from '@/use/calcualtorHandler2'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import { ref, onMounted, onUnmounted } from 'vue'
import translationHandler from '@/plugins/i18n/translationHandler'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const calculatorHandler = useCalculator()
    const selectOpen = ref(false)
    const selectRef = ref(null)
    const useTranslate = translationHandler()

    function requiredAndFilled(field) {
      if (field.required) {
        const fieldValue = props.handler.data.value[field.key]
        if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
          return true
        }
        return false
      }
      return true
    }

    function dependentCheck(field) {
      const checkValues = field.dependent_show_values

      if (checkValues) {
        const dependentShowFieldKey = field.dependent_show_field_key
        const dependentShowFieldType = field.dependent_show_field_type

        if (dependentShowFieldType === 'select') {
          if (
            props.handler.data.value[dependentShowFieldKey] &&
            checkValues.includes(props.handler.data.value[dependentShowFieldKey].value)
          ) {
            return true
          }
        }
        if (
          props.handler.data.value[dependentShowFieldKey] &&
          checkValues.includes(props.handler.data.value[dependentShowFieldKey])
        ) {
          return true
        }
        return false
      }
      return true
    }

    function parsedFilename(value) {
      if (value) {
        if (typeof value.name === 'string') {
          return value.name
        } else {
          return value
        }
      } else return value
    }

    function toggleSelect() {
      selectOpen.value = !selectOpen.value
    }

    function clicker(event) {
      if (selectRef.value) {
        if (!selectRef.value.contains(event.target)) {
          if (selectOpen.value) {
            toggleSelect()
          }
        }
      }
    }

    function getLabel(value, optionsArray) {
      const foundOption = optionsArray.find((option) => option.value === value)
      return foundOption ? foundOption.label : ''
    }

    function incrementValue(key, step) {
      // Get current value, default to 0 if empty/invalid
      let currentValue = props.handler.data.value[key]
      if (currentValue === '' || currentValue === null || currentValue === undefined) {
        currentValue = 0
      } else {
        currentValue = parseInt(currentValue, 10)
        if (isNaN(currentValue)) {
          currentValue = 0
        }
      }

      // Calculate new value (step is already correct in the button click handlers)
      const newValue = currentValue + step

      // Create a mock event object to match the input event structure
      const mockEvent = {
        target: {
          value: newValue.toString(),
        },
      }

      // Use the same validation handler as the input
      props.handler.validate(mockEvent, props.field.integer_type, [], key)
    }

    onMounted(() => {
      window.addEventListener('click', clicker)
    })

    onUnmounted(() => window.removeEventListener('click', clicker))

    return {
      calculatorHandler,
      requiredAndFilled,
      dependentCheck,
      parsedFilename,
      toggleSelect,
      selectOpen,
      selectRef,
      getLabel,
      useTranslate,
      incrementValue,
    }
  },
  components: {
    CalculatorIcon,
    featherIcon,
  },
}
</script>
