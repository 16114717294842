export default () => {
  function dateFromEpochDay(value) {
    if (value) {
      const date = new Date(value)
      const dateFormatted = `${`0${date.getDate()}`.slice(-2)}-${`0${date.getMonth() + 1}`.slice(
        -2
      )}-${date.getFullYear()}`
      return dateFormatted
    }
    return ''
  }

  function dateTimeFromEpoch(value) {
    if (value) {
      const date = new Date(value)

      // Format date
      const dateFormatted = `${`0${date.getDate()}`.slice(-2)}-${`0${date.getMonth() + 1}`.slice(
        -2
      )}-${date.getFullYear()}`

      // Format time
      const hours = `0${date.getHours()}`.slice(-2)
      const minutes = `0${date.getMinutes()}`.slice(-2)
      const timeFormatted = `${hours}:${minutes}`

      // Combine date and time
      return `${dateFormatted} ${timeFormatted}`
    }
    return ''
  }

  function toCurrencyRound(value) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }

  function toCurrencyNoRound(value) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
    })
    return formatter.format(value)
  }

  function toCurrencyWhole(value) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    return formatter.format(value)
  }

  function round(value) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    return formatter.format(value)
  }

  function round2(value) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }

  function round2en(value) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      useGrouping: false,
    })
    return formatter.format(value)
  }

  function roundDynamic(value) {
    if (typeof value !== 'number') {
      return value
    }

    let maximumFractionDigitsStore = 0

    if (Math.abs(value) < 1) {
      maximumFractionDigitsStore = 3
    } else if (Math.abs(value) < 10) {
      maximumFractionDigitsStore = 2
    }

    const formatter = new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionDigitsStore,
    })
    return formatter.format(value)
  }

  function roundDynamicCurrency(value) {
    if (typeof value !== 'number') {
      return value
    }

    let maximumFractionDigitsStore = 0

    if (Math.abs(value) < 1) {
      maximumFractionDigitsStore = 3
    } else if (Math.abs(value) < 10) {
      maximumFractionDigitsStore = 2
    }

    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionDigitsStore,
    })
    return formatter.format(value)
  }

  function kWh(value) {
    if (value || value === 0) {
      return `${roundDynamic(value)} kWh`
    } else return '-'
  }

  function m3(value) {
    if (value || value === 0) {
      return `${roundDynamic(value)} m3`
    } else return '-'
  }

  function GJ(value) {
    if (value || value === 0) {
      return `${roundDynamic(value)} GJ`
    } else return '-'
  }

  function power(value, utility) {
    let returnValue = ''
    if (value) {
      returnValue += `${value}`
    } else {
      returnValue += ''
    }

    if (utility && returnValue !== '') {
      const utilitySelector = {
        Electricity: ' kW',
        Gas: ' m3',
      }
      returnValue += utilitySelector[utility]
    }
    return returnValue
  }

  function hoursFromNumber(value) {
    const selector = {
      0: '00:00',
      1: '01:00',
      2: '02:00',
      3: '03:00',
      4: '04:00',
      5: '05:00',
      6: '06:00',
      7: '07:00',
      8: '08:00',
      9: '09:00',
      10: '10:00',
      11: '11:00',
      12: '12:00',
      13: '13:00',
      14: '14:00',
      15: '15:00',
      16: '16:00',
      17: '17:00',
      18: '18:00',
      19: '19:00',
      20: '20:00',
      21: '21:00',
      22: '22:00',
      23: '23:00',
    }

    return selector[value]
  }

  function filter(value, type, utility) {
    const selector = {
      dateFromEpochDay: dateFromEpochDay(value),
      dateTimeFromEpoch: dateTimeFromEpoch(value),
      currencyRound: toCurrencyRound(value),
      toCurrencyNoRound: toCurrencyNoRound(value),
      round: round(value),
      round2: round2(value),
      round2en: round2en(value),
      roundDynamic: roundDynamic(value),
      roundDynamicCurrency: roundDynamicCurrency(value),
      kWh: kWh(value),
      m3: m3(value),
      power: power(value, utility),
      hoursFromNumber: hoursFromNumber(value),
      GJ: GJ(value),
    }

    return type ? selector[type] : value
  }

  return {
    dateFromEpochDay,
    toCurrencyRound,
    round,
    round2,
    filter,
    toCurrencyWhole,
    toCurrencyNoRound,
    roundDynamic,
    roundDynamicCurrency,
  }
}
