<template>
  <span>
    <div class="dropdown relative">
      <!-- input -->
      <input
        @focus="onChange()"
        ref="input"
        class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
        v-model="geoLocatorHandler.searchInput.value"
      />

      <ul v-if="show" class="mt-1 absolute w-full border border-gray-200 py-1 text-sm text-gray-700 dark:text-gray-200">
        <li
          class="cursor-pointer"
          @click="onSelect(index)"
          v-for="(result, index) in geoLocatorHandler.searchResult.value"
          :key="index"
        >
          <span
            class="bg-white flex justify-between block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <span
              ><b>{{ result.type }}</b></span
            >
            <span>{{ result.weergavenaam }}</span>
          </span>
        </li>
      </ul>
    </div>
  </span>
</template>

<script>
import useGeoLocator from '@/use/useGeoLocator'
import { computed, ref } from 'vue'
export default {
  setup() {
    const input = ref(null)
    let isFocussed = ref(false)
    const geoLocatorHandler = useGeoLocator()
    const show = computed(() => {
      if (
        geoLocatorHandler.searchResult.value.length !== 0 &&
        geoLocatorHandler.searchInput.value.length !== 0 &&
        isFocussed.value
      ) {
        return true
      }
      return false
    })

    function onChange() {
      isFocussed.value = input.value === document.activeElement ? true : false
    }

    function onSelect() {
      // pass
      // console.log(index)
    }

    return {
      geoLocatorHandler,
      show,
      input,
      onChange,
      isFocussed,
      onSelect,
    }
  },
}
</script>
