<template>
  <div class="h-full min-h-0 overflow-auto p-4">
    <base-page-title class="mb-8 mt-4" :title="pageTitle" :suffix="''" :icon="meta.titleIcon"></base-page-title>
    <div v-if="useConnectorDetails.dataHealthTime.value && useConnectorDetails.dataHealthTime.value.length > 0">
      <!-- Status Header -->
      <div class="mb-6">
        <div class="text-lg font-medium mb-1">
          {{ useConnectorDetails.data.value?.description || 'Connector' }} Status
        </div>
        <div class="text-sm text-gray-600">{{ useConnectorDetails.calculateUptime }}% Uptime</div>
      </div>

      <!-- Timeline Chart -->
      <div class="bg-white rounded-lg shadow-sm p-4">
        <vue-highcharts
          type="chart"
          :options="useConnectorDetails.chartOptions"
          :redrawOnUpdate="true"
          :oneToOneUpdate="false"
          :animateOnUpdate="true"
          ref="root"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { onMounted, computed } from 'vue'
import connectorDetailsHandler from '@/use/viewHandlerConnectorDetails'
import VueHighcharts from '@/use/highCharts'

export default {
  setup() {
    const route = useRoute()
    const useConnectorDetails = connectorDetailsHandler()
    const objectId = route.params.objectId
    const meta = computed(() => route.meta)
    const pageTitle = computed(() => meta.value.title)

    onMounted(() => {
      useConnectorDetails.getDetails(objectId)
    })

    return {
      useConnectorDetails,
      objectId,
      pageTitle,
      meta,
    }
  },
  components: {
    VueHighcharts,
  },
}
</script>
